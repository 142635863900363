
/* eslint-disable camelcase */
import Vue from 'vue';
import Component from 'vue-class-component';
import {PropSync} from 'vue-property-decorator';

/**
 * @vue-prop {String} title - Title of the card
 * @vue-prop {Boolean} __PROPIsPremium - If the option is a premium feature the card is disabled.
 */

@Component

/**
 * Intelligent component for Editing Profile, component allows for the editing of a user profile
 */
export default class Card extends Vue {
  name: 'Card';

  @PropSync('title', {default: null, required: true, type: String})__PROPtitle: String;
  @PropSync('isPremium', {default: false, type: Boolean})__PROPisPremium: Boolean;
  @PropSync('premiumMessage', {default: '', type: String})__PROPpremiumMessage: String;
  @PropSync('description', {default: '', type: String})__PROPdescription: String;
  @PropSync('icon', {default: '', type: String})__PROPicon: String;
}
