<template>
  <div v-observe-visibility="visibilityChanged">
    <p
      v-if="showNoMeetingsMessage"
      class="pl-4 m-0"
    >
      No previous meetings
    </p>
    <p
      v-else-if="showNoLicenseMessage"
      class="pl-4 m-0"
    >
      As you have no license we are unable to show you your previous meetings<br>
      to view your meetings obtain a license for your account.
    </p>
    <div class="table-responsive">
      <table
        v-if="prevMeetingsData().length && !showNoLicenseMessage"
        class="table table-borderless table-data"
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Time</th>
            <th>Direct URL</th>
            <th class="th-record-status">
              Record Status
            </th>
          </tr>
        </thead>
        <tbody
          v-for="item in prevMeetingsData()"
          :key="item.date"
        >
          <tr class="tr-date">
            <td colspan="4">
              {{ item.date | moment('dddd, D MMMM') }}
            </td>
          </tr>
          <tr
            v-for="meeting in item.data_list"
            :key="meeting.id"
          >
            <td>{{ meeting.subject }}</td>
            <td>{{ meeting.starts_at | moment('HH:mm') }} - {{ meeting.ends_at | moment('HH:mm') }}</td>
            <td>
              <a
                :href="meetingRoomDirectLink(meeting.id_alias)"
                target="_blank"
              >{{ meeting.id_alias }}</a>
            </td>
            <td class="td-record-status">
              <div
                v-for="record in meeting.recordings"
                :key="record.id"
                class="mx-1"
              >
                <div v-if="record.status === 'Processing'">
                  <span class="fa fa-cog fa-spin" /> Processing...
                </div>

                <div v-if="record.status === 'Processed'">
                  <a
                    v-if="record.mp4"
                    class=""
                    :href="record.mp4"
                    download
                    target="_blank"
                  >
                    Video
                  </a>

                  <a
                    v-if="record.audio_status === 'Processed'"
                    class=""
                    :href="record.mp3"
                    download
                    target="_blank"
                  >
                    Audio
                  </a>

                  <span
                    v-if="record.audio_status === 'Processing'"
                    class="ml-1"
                  >
                    <span class="fa fa-cog fa-spin" />
                    Audio processing...
                  </span>
                </div>
              </div>

              <small v-if="!meeting.recordings.length">No recordings</small>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-right mt-1 mr-4 text-grey-light">
        <router-link :to="{name: 'settings'}">
          <small v-if="prevMeetingsData().length && auth">
            Timezone {{ auth.timezone.replace('_', ' ') }}
          </small>
        </router-link>
      </div>
    </div>
    <div
      v-if="previousMeetingList.last_page > 1"
      class="row"
    >
      <div class="col-12 p-3">
        <label class="float-right font-weight-bold m-2">
          {{ previousMeetingList.total }} item{{ previousMeetingList.total > 1 ? 's' : '' }},
          {{ previousMeetingList.last_page }} page{{ previousMeetingList.last_page > 1 ? 's' : '' }}
        </label>
        <div class="pagination float-right">
          <button
            class="btn btn-outline fas fa-step-backward"
            :disabled="curPage === 1"
            @click="go(1)"
          />
          <button
            class="btn btn-outline fas fa-angle-left"
            :disabled="curPage === 1"
            @click.prevent="go(curPage-1)"
          />
          <input
            v-model="curPage"
            type="text"
            class="text-center"
            @keyup.enter="go(curPage)"
          >
          <button
            class="btn btn-outline fas fa-angle-right"
            :disabled="curPage === previousMeetingList.last_page"
            @click.prevent="go(curPage+1)"
          />
          <button
            class="btn btn-outline fas fa-step-forward"
            :disabled="curPage === previousMeetingList.last_page"
            @click.prevent="go(previousMeetingList.last_page)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DirectLinkMixin from '@/mixins/DirectLinkMixin';
import {mainStore} from '@/store/__STORE_main';
import {userStore} from '@/store/__STORE_user';
import {meetingStore} from '@/store/__STORE_meetings';

export default {
  components: {},
  mixins: [
    DirectLinkMixin,
  ],
  props: {},
  data: () => ({
    showNoMeetingsMessage: false,
    curPage: '',
  }),
  computed: {
    previousMeetingList() {
      return meetingStore.previousMeetings;
    },
    auth() {
      return userStore.profileData;
    },
    isLoading() {
      return mainStore.status === 'loading';
    },
    showNoLicenseMessage() {
      return userStore.__GETTERisUserOnNoLicense;
    },
  },
  watch: {
    previousMeetingList(val) {
      this.curPage = val.current_page;
    },
  },
  mounted() {
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.previousMeetingList.data) {
        this.getData();
      }
    },
    getData() {
      // @TODO pagination
      const param = {
        page_num: 1,
      };
      meetingStore.getPreviousMeetingsData(param);
    },
    prevMeetingsData() {
      const prevMeetingsData = [];
      if (!this.previousMeetingList.data) return prevMeetingsData;
      let moveDate = '';
      let moveList = [];
      const vm = this;
      for (const item of this.previousMeetingList.data) {
        const curDate = vm.$moment(item.starts_at).format('YYYY-MM-DD');
        if (moveDate != curDate) {
          if (moveDate) {
            prevMeetingsData.push({
              date: moveDate,
              data_list: moveList,
            });
            moveList = [];
          }
        }
        moveDate = curDate;
        const detailMeeting = {
          id: item.id,
          subject: item.subject,
          description: item.description,
          starts_at: item.starts_at,
          ends_at: item.ends_at,
          calendar: item.calendar,
          meeting_room: item.meeting_room,
          id_alias: item.meeting_room.webrtc.alias,
          recordings: item.recordings,
        };
        moveList.push(detailMeeting);
      }
      if (moveDate) {
        prevMeetingsData.push({
          date: moveDate,
          data_list: moveList,
        });
      }

      window.setTimeout(() => {
        if (!this.isLoading && !prevMeetingsData.length) {
          this.showNoMeetingsMessage = true;
          return;
        }
        this.showNoMeetingsMessage = false;
      }, 500);

      return prevMeetingsData;
    },
    go(pageNum) {
      const param = {
        page_num: pageNum,
      };
      meetingStore.getPreviousMeetingsData(param);
    },
  },
};
</script>

<style lang="scss" scoped>
  // .record-status {
  //   position: relative;
  //   display: inline-flex;
  //   align-items: center;
  // }

  // .record-status-svg {
  //   transform: rotate(-90deg);
  //   display: none;
  //   margin-right: 5px;
  // }

  // .record-status-text {
  //   font-weight: 600;

  //   color: $color-gray;
  //   > span {
  //     font-weight: 400;

  //     margin-left: 1rem;
  //   }
  // }

  // .record-status-recording {
  //   .record-status-svg {
  //     display: block;
  //   }
  //   .record-status-text {
  //     > span {
  //       color: $record-status;
  //     }
  //   }
  // }

  // .record-status-not-recorded {
  //   .record-status-text {
  //     font-style: italic;
  //     font-weight: 400;

  //     color: $inactive-text-color;
  //   }
  // }

  .td-record-status,
  .th-record-status {
    text-align: right;
  }
</style>
