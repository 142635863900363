<template>
  <div
    class="dropdown d-inline-block table-action cursor-pointer"
  >
    <slot>
      <a
        :id="'sendInvites-' + meeting.id"
        class="dropdown-toggle"
        data-toggle="dropdown"
      >
        <icon-plus-primary
          class="mr-1"
        />
        Send Invites
      </a>
    </slot>
    <div
      class="dropdown-menu"
      :aria-labelledby="'sendInvites-' + meeting.id"
    >
      <a
        class="dropdown-item"
        :href="meeting.calendar.google"
        target="_blank"
      >
        Google Calendar
      </a>

      <a
        class="dropdown-item"
        :href="meeting.calendar.ics"
        :download="icsFilename"
      >
        Download ICS
      </a>

      <a
        class="dropdown-item"
        @click="copyTextInvite"
      >
        Copy Text invite
      </a>

      <a
        class="dropdown-item"
        @click="copyUrl"
      >
        Copy URL
      </a>

      <textarea
        ref="txtInvite"
        class="position-absolute hidden"
        type="text"
        :value="textInvite(meeting)"
      />

      <input
        ref="txtUrl"
        class="position-absolute hidden"
        type="text"
        :value="url"
      >
    </div>
  </div>
</template>
<script>
import IconPlusPrimary from '@/components/icons/IconPlusPrimary.vue';
import TextInviteMixin from '@/mixins/TextInviteMixin';
export default {
  components: {
    IconPlusPrimary,
  },
  mixins: [TextInviteMixin],
  props: {
    meeting: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    url() {
      if (!this.meeting.meeting_room) {
        return '';
      }

      const a = document.createElement('a');
      a.href = this.$router.resolve({
        name: 'welcome-guest',
        params: {webrtcAlias: this.meeting.meeting_room.webrtc.alias},
      }).href;
      return a.href;
    },
    icsFilename() {
      return 'SimplyVideo Invite ' + this.meeting.id;
    },
  },
  mounted() {
  },
  methods: {
    copyUrl() {
      this.copyToClipboard(
          this.$refs.txtUrl,
      );
    },
    copyTextInvite() {
      this.copyToClipboard(
          this.$refs.txtInvite,
      );
    },
    copyToClipboard(domElement) {
      domElement.setAttribute('type', 'text');
      domElement.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.$toast.success('Copied successfully');
        } else {
          this.$toast.error('Oops, unable to copy');
        }
      } catch (err) {
        this.$toast.error('Oops, unable to copy');
      }

      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  font-size: 1.6rem;
}
.hidden {
  left:-9999px;
  opacity: 0;
  z-index:-1;
}
</style>
