<template>
  <content-window>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a href="#">Dashboard</a>
      </li>
      <li class="breadcrumb-item active">
        Meetings
      </li>
    </ol>
    <div class="section-header">
      <div class="section-header-title">
        <router-link
          class="section-header-back"
          :to="{name: 'dashboard'}"
        >
          <i class="fas fa-arrow-circle-left" />
        </router-link>
        <h1>My Meetings</h1>
      </div>
      <div class="section-header-action">
        <router-link
          v-if="!isUserOnNoLicense"
          :to="{ name:'meeting' }"
          class="btn btn-secondary"
        >
          <icon-plus class="mr-2 d-flex gray" />
          Create Meeting
        </router-link>
      </div>
    </div>

    <div class="row row-has-panel">
      <div :class="isShowGotcha ? 'col-xl-9' : 'col-xl-12'">
        <p
          v-if="isUserOnNoLicense"
          class="warning-text"
        >
          You are unable to schedule meetings as you do not have a license. Contact your account admin to resolve this.
        </p>
        <div class="section-panel">
          <div class="section-table-tabs">
            <ul
              class="nav nav-tabs"
              role="tablist"
            >
              <li>
                <a
                  class="active"
                  href="#tab-upcoming"
                  data-toggle="tab"
                  role="tab"
                >Upcoming</a>
              </li>
              <li>
                <a
                  href="#tab-previous"
                  data-toggle="tab"
                  role="tab"
                >Previous meetings</a>
              </li>
            </ul>
          </div>
          <div class="section-panel-body">
            <div class="tab-content">
              <div
                id="tab-upcoming"
                class="tab-pane fade show active"
              >
                <div class="table-date-row-wrapper">
                  <upcoming-meetings-table />
                </div>
              </div>
              <div
                id="tab-previous"
                class="tab-pane fade"
              >
                <div class="table-date-row-wrapper">
                  <previous-meetings-table />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-panel">
          <div class="section-panel-body">
            <plugins />
          </div>
        </div>
      </div>
      <div
        v-if="isShowGotcha"
        class="col-xl mt-2 mt-xl-0"
      >
        <gotcha
          :tip-list="tipList"
          @close="isShowGotcha = false"
        />
      </div>
    </div>
    <!-- <transition name="fade">
          <confirm-billing-method title="Join meeting" v-if="isModalShow" @close="isModalShow = false"></confirm-billing-method>
        </transition> -->
  </content-window>
</template>

<script>
import Gotcha from '@/components/Gotcha.vue';
import UpcomingMeetingsTable from '@/components_v2/intelligent/meeting/__COMP_UpcomingMeetingsTable.vue';
import PreviousMeetingsTable from '@/components/PreviousMeetingsTable.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import Plugins from '@/components/Plugins.vue';
import Styles from '@/mixins/styleMixin';
import PlanMixin from '@/mixins/PlanMixin';

export default {
  components: {
    Gotcha,
    // ConfirmBillingMethod,
    UpcomingMeetingsTable,
    PreviousMeetingsTable,
    IconPlus,
    Plugins,
  },
  mixins: [Styles, PlanMixin],
  data() {
    return {
      isShowGotcha: true,
      tipList: [
        'You will find your upcoming and previous meeting details here',
        'You can join any upcoming meeting or check out details of historic ones too.',
      ],
      splitDate: '',
      isModalShow: false,
      curMeetingId: '',
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/__variables.scss';

  .section-table-tabs {
    border-radius: 3px 3px 0 0;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;

    ul {
      border-bottom: 0;
      li a {
        border-radius: 3px 3px 0 0;
        color: $white;
        display: block;
        font-weight: 600;
        letter-spacing: -0.2px;
        padding: 16px 20px;

        &.active {
          background-color: $white;
          color: $color-body;
        }
      }
    }
  }
</style>

