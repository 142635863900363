
/* eslint-disable camelcase */
import Component, {mixins} from 'vue-class-component';

import {userStore, TProfile} from '@/store/__STORE_user';
import {meetingStore} from '@/store/__STORE_meetings';
import {mainStore} from '@/store/__STORE_main';
import MeetingInvitesDropdown from '@/components/MeetingInvitesDropdown.vue';
import DirectLink from '@/mixins/DirectLink.ts';
import DateFormat from '@/mixins/DateFormat.ts';
import IconEdit from '@/components/icons/IconEdit.vue';

import {format} from 'date-fns';

interface IMeetingList {
  current_page: number,
  data: Array<IMeeting>,
  first_page_url: String,
  from: number,
  last_page: number,
  last_page_url: String,
  links: Array<any>,
  next_page_url: String | null
  path: String,
  per_page: number,
  prev_page_url: String | null
  to: number,
  total: number,
}

interface IMeeting {
  id: number,
  subject: String,
  description: String,
  starts_at: String,
  ends_at: String,
  timezone: String,
  timezone_offset: String,
  calendar: Object,
  meeting_room: {
    webrtc?: {
      alias?: String
    },
  },
  recordings: Object,
}

interface IMeetingListByDate {
  [key: string]: Array<IMeeting>,
}

@Component({
  components: {
    MeetingInvitesDropdown,
    IconEdit,
  },
})
/**
 * Intelligent component for controlling the settings area for participants
 * Allows for disconnecting and muting all participants as well as interacting
 * with the room lock
 */
export default class UpcomingMeetingsTable extends mixins(DirectLink, DateFormat) {
  name: 'Upcoming Meetings Table'

  showNoMeetingsMessage: boolean = false;

  /**
   * Gets the upcoming meetings list from the meetingStore
   */
  get upcomingMeetingList(): IMeetingList {
    return meetingStore.upcomingMeetings;
  }
  /**
   * Groups the upcoming meetings list within a date separated array
   */
  get upcomingMeetingListData(): IMeetingListByDate {
    if (this.upcomingMeetingList && this.upcomingMeetingList.data && this.upcomingMeetingList.data.length) {
      const meetingsByDay = this.upcomingMeetingList.data.reduce(
          (groupedMeetings, meeting) => {
            const date = this.__MIXINformatDateYMD(meeting.starts_at);
            if (!groupedMeetings[date]) {
              groupedMeetings[date] = [];
            }
            groupedMeetings[date].push(meeting);
            return groupedMeetings;
          },
          {});
      return meetingsByDay;
    }
    return null;
  }
  /**
   * Gets personal settings from the userStore
   */
  get auth(): TProfile {
    return userStore.profileData;
  }
  /**
   * Gets the loading status from the mainStore
   */
  get isLoading(): Boolean {
    return mainStore.status === 'loading';
  }
  /**
   * Gets whether the user is on no license from the userStore
   */
  get showNoLicenseMessage(): Boolean {
    return userStore.__GETTERisUserOnNoLicense;
  }

  /**
   * Watch curPage and update meeting list to page
   */
  get curPage(): number {
    return this.upcomingMeetingList ? this.upcomingMeetingList.current_page : 0;
  }

  /**
   * Methods called on component creation
   */
  created(): void {
    userStore.getUserProfile();
    meetingStore.getUpcomingMeetingsData();
  }

  /**
   * Delete the selected meeting
   * @param {number} id the ID of the meeting to be deleted
   */
  deleteMeeting(id:number):void {
    meetingStore.deleteMeeting(id)
        .then(() => {
          this.$toast.success('Meeting deleted!');
        })
        .catch((err) => {
          this.$toast.error(err);
        });
  }

  /**
   * Get the next page of meetings
   * @param {number} pageNum - the requested page number
   */
  go(pageNum:number): void {
    meetingStore.getUpcomingMeetingsData(pageNum);
  }
  /**
   * Get the date of the first meeting of each day as 'Day date month'
   * @param {Array<IMeeting>} meetings - array of meetings on one day
   * @return {string}
   */
  meetingDay(meetings:Array<IMeeting>): string {
    return format(this.__MIXINparseDate(meetings[0].starts_at), 'cccc, do MMMM');
  }
}
