export default {
  methods: {
    webrtcAliasDirectLink(webrtcAlias) {
      const a = document.createElement('a');
      a.href = this.$router.resolve({
        name: 'welcome-guest',
        params: {
          webrtcAlias: webrtcAlias,
        },
      }).href;
      return a.href;
    },
    meetingRoomDirectLink(webrtcAlias) {
      // return this.webrtcAliasDirectLink(meetingRoom.webrtc.alias);
      const a = document.createElement('a');
      a.href = this.$router.resolve({
        name: 'host',
        params: {
          webrtcAlias: webrtcAlias,
        },
      }).href;
      return a.href;
    },
    meetingDirectLink(meeting) {
      return this.webrtcAliasDirectLink(meeting.meeting_room.webrtc.alias);
    },
  },
};
