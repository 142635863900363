
/* eslint-disable camelcase */
import Vue from 'vue';
import Component from 'vue-class-component';

@Component

/**
 * Intelligent component for Editing Profile, component allows for the editing of a user profile
 */
export default class ContentWindow extends Vue {
  name: 'ContentWindow';
}
