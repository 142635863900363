export default {
  methods: {
    textInvite(meeting) {
      if (!meeting.meeting_room) {
        return '';
      }


      return 'You\'ve been invited to a meeting by ' +
                meeting.meeting_room.host.name + ' ' + meeting.meeting_room.host.last_name +
                ' using SimplyVideo.' +
                '\n' +
                '\n' +
                'Subject: ' + meeting.subject +
                '\n' +
                'Time: ' + this.$moment(meeting.starts_at).format('DD-MM-YYYY HH:mm') +
                ' - ' + this.$moment(meeting.ends_at).format('HH:mm') +
                ' (GMT' + meeting.timezone_offset + ')' +
                // + '\n'
                // + 'Timezone: ' + meeting.timezone.replace('_', ' ') + ' GMT' + meeting.timezone_offset + ''
                '\n' +
                '\n' +
                'To get started click on the link below.' +
                '\n' +
                this.url +
                '\n' +
                '\n' +
                'Want to know more about SimplyVideo?' +
                '\n' +
                'Learn more at www.simplyvideo.io'
      ;
    },
  },
};
